@import 'assets/styles/mixins.scss';

.csvTemplateLink {
  color: $pbp-green;
}

.uploadCSV:hover span {
  color: $pbp-green;
  transition: 0.2s;
}

.uploadCSV span {
  transition: 0.2s;
}

.modalFooter {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.downloadButton {
  margin-right: auto;
}

.driverImportUploader {
  display: flex;
  align-items: center;
  button {
    margin-right: spacing(1);
  }
}

.driverLoading {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.importFailedContainer {
  margin-top: spacing(4);
}

.errorTitle {
  margin-bottom: spacing(1) !important;
  color: $error-dark !important;
}

.importFailedDetail {
  display: flex;
  justify-content: space-between;
}

.genericError {
  padding-top: spacing(1) !important;
  color: $error-dark !important;
}

.divider {
  margin: spacing(0.5) 0 !important;
}

.serverErrorALert {
  padding: spacing(2);
  align-items: flex-start;
  border: 1px solid $error-dark;
  background-color: $error-light;
  svg {
    margin-top: spacing(0.5);
    color: $error-dark;
  }
}

.menuItem_driverImportInvitationOptions:global(.ant-form-item) {
  :global(.ant-form-item) {
    margin-bottom: 0px !important;
  }
}

.recaptchaContainer {
  display: flex;
  justify-content: center;
}
