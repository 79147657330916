@import 'assets/styles/mixins.scss';

.modalContainer {
  max-width: 600px;
  :global(.ant-modal-body) {
    padding: spacing(1) spacing(0) spacing(1) spacing(0) !important;
  }
  :global(.ant-modal-content) {
    border-radius: spacing(2) !important;
    padding-top: spacing(2);
    padding-bottom: spacing(2);
  }
  :global(.ant-modal-header) {
    border-radius: spacing(2) !important;
  }
  :global(.ant-modal-header) {
    border-radius: spacing(2) !important;
  }
}

.modalTitle {
  text-align: center;
  width: 100%;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: spacing(3);
  padding: spacing(1) spacing(5) spacing(1) spacing(5);
  font-size: $md-font-size;

  > *:nth-child(1) {
    color: #6cc24a;
  }
}

.contentTitle {
  font-weight: 700 !important;
  font-family: FoundryContext-Bold !important;
  margin-bottom: 0px !important;
}

.footer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: spacing(2);
  padding: spacing(1) spacing(2) spacing(0) spacing(2);
}

.closeButton {
  border: #808080 solid 1px;
  border-radius: spacing(1);
  height: spacing(5);
}

.buttonAsLink {
  cursor: pointer;

  @include transition-color();
  color: $text-lighten !important;
  &:hover {
    color: $pbp-green !important;
  }
}

.hyperlink {
  text-decoration: underline !important;
}
