@import 'assets/styles/mixins.scss';

.onboardingPage {
  display: flex;
  flex-direction: column;
  margin: spacing(3) spacing(2) spacing(2) spacing(2);
}

.OnboardingPageHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: spacing(3);
  div {
    font-size: $base-font-size;
    margin-bottom: spacing(0);
  }
}

// Change activation name to content
.activation {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.activationMessage {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: spacing(2);
  max-width: 340px;
  p {
    font-size: $base-font-size;
    margin-bottom: spacing(0);
  }
}

.activationButton {
  margin-top: spacing(3);
  width: spacing(22) !important;
  height: spacing(5) !important;
}

.notificationPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  p {
    font-size: $base-font-size;
    margin-bottom: spacing(0);
  }
}

.notificationErrorTitle {
  color: $danger !important;
  width: 260px;
  text-align: center;
}

.notificationSuccessTitle {
  color: #66c24a !important;
  width: 260px;
  overflow-wrap: normal;
  text-align: center;
  margin-bottom: 16px !important;
}

.notificationIcon {
  margin-bottom: spacing(2);
}

.registrationForm {
  max-width: 300px;
}

.registrationFormTitle {
  color: #66c24a !important;
  overflow-wrap: normal;
  text-align: center;
}

.registrationDescription {
  p {
    font-size: $base-font-size;
    margin-bottom: spacing(2);
  }
}

.recaptcha {
  width: 80%;
}

.registrationButton {
  display: flex;
  justify-content: center;
  margin-top: spacing(2);
}

.socialSignIn {
  display: flex;
  flex-direction: column;
  margin-top: spacing(3);
  margin-bottom: spacing(4);
  p {
    font-size: $base-font-size;
    margin-bottom: spacing(2);
  }
}

.socialSignInButtons {
  display: flex;
  justify-content: center;
  gap: 15px;
}

.appleSignInButton {
  width: 44px;
  height: 44px;
  cursor: pointer;
}

.facebookSignInButton {
  width: 45px;
  height: 45px;
  padding: 0;
  border: none;
  cursor: pointer;
}

.facebookSignImg {
  width: 100%;
  height: 100%;
}

.carouselContainer {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  border: 1px solid #d9d9d9;
  width: spacing(50);
  height: spacing(76);
  border-radius: spacing(3);
  margin: auto;
  margin-bottom: spacing(8);
  overflow: hidden;
}

.carousel {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  width: spacing(50);

  div {
    height: spacing(66);
    top: 0;
  }
}

.carouselCard {
  display: flex !important;
  flex-direction: column;
  padding-top: spacing(4);
  align-items: center;

  h4 {
    width: spacing(41);
    text-align: center;
  }

  article {
    width: spacing(41);
    text-align: center;
    font-size: spacing(2);
    margin-left: spacing(1);
    margin-right: spacing(1);
  }
}

.carouselCardTitle {
  margin-top: spacing(2) !important;
  margin-bottom: spacing(1) !important;
}

.carouselCardSubText {
  color: #4d4d4d !important;
}

.actionBtnscontainer {
  display: flex;
  justify-content: center;
}

.actionBtns {
  position: absolute;
  padding-top: spacing(3);
  display: flex;
  justify-content: center;
  align-items: center;
}

.actionBtn {
  position: absolute !important;
  width: spacing(22) !important;
  height: spacing(5) !important;
  border-radius: 6px;
}

.backBtn {
  position: absolute;
  margin-right: spacing(35);
  color: #9b9b9b;
  border: none;
  transition: 0.3s;
}

@media screen and (max-width: 768px) {
  .carouselContainer {
    width: 100%;
    border: none;
  }
}
